import {init, Announcement} from '@shopify/marketing-assets';

document.addEventListener('DOMContentLoaded', () => {
  init();
  gtagEvent();
  initAnnouncement();
});

function gtagEvent() {
  const signupBtns = document.querySelectorAll('[data-event-action]');
  [...signupBtns].forEach((button) => {
    button.addEventListener('click', () => {
      gtag('event', button.dataset.eventAction, { // eslint-disable-line no-undef
        event_category: button.dataset.eventCategory, // eslint-disable-line babel/camelcase
        event_label: button.dataset.eventLabel, // eslint-disable-line babel/camelcase
      });
    }, false);
  });
}


function initAnnouncement() {
  const announcement = document.querySelector('.js-announcement');
  if (announcement) {
    new Announcement(announcement); // eslint-disable-line no-new
  }
}
